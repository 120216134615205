import { Box, lighten, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { createContext, TouchEventHandler, useCallback, useContext, VFC } from "react";
import { ReclaimEdition } from "../../reclaim-api/Users";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.caption,
    backgroundColor: lighten(theme.colors.grey, 0.6),
    borderRadius: 4,
    color: theme.palette.text.primary,
    display: "inline-block",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "16px",
    padding: theme.spacing(0, 0.5),
    textTransform: "uppercase",
    pointerEvents: "auto",
  },
  paid: {
    backgroundColor: theme.colors.logo.corn,
  },
  medium: {},
  small: {},
  slim: {},
}));

export type EditionBadgeProps = {
  edition: ReclaimEdition;
  className?: string;
  size?: "slim" | "small" | "medium";
};

export type EditionBadgeContextData = { tooltipOpen: boolean; setTooltipOpen: (open: boolean) => void };
export const EditionBadgeContext = createContext<EditionBadgeContextData>({
  tooltipOpen: false,
  setTooltipOpen: () => void 0,
});

export const EditionBadge: VFC<EditionBadgeProps> = ({ edition, className, size = "medium" }) => {
  const classes = useStyles();
  const { tooltipOpen, setTooltipOpen } = useContext(EditionBadgeContext);

  const handleTouchEndCapture = useCallback<TouchEventHandler<HTMLDivElement>>(
    (e) => {
      if (!tooltipOpen) {
        // preventDefault on touchEnd blocks click event
        e.preventDefault();
        e.stopPropagation();
        setTooltipOpen(true);
      } else setTooltipOpen(false);
    },
    [setTooltipOpen, tooltipOpen]
  );

  return (
    <Box
      component="div"
      onTouchEndCapture={handleTouchEndCapture}
      className={clsx(classes.root, className, {
        [classes.paid]: edition !== ReclaimEdition.Free,
        [classes.medium]: size === "medium",
        [classes.small]: size === "small",
        [classes.slim]: size === "slim",
      })}
    >
      {edition.label}
    </Box>
  );
};
