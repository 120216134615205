import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiTooltip, { TooltipClassKey, TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";
import { ClassNameMap } from "@material-ui/styles";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      position: "relative",
      maxWidth: 250,
      padding: theme.spacing(0.5, 1),
    },
    arrow: {},
  })
);

export type TooltipJSSClassKey = TooltipClassKey | keyof ReturnType<typeof useStyles>;

export type TooltipProps = MuiTooltipProps & {
  className?: string;
  classes?: Partial<ClassNameMap<TooltipJSSClassKey>>;
  disabled?: boolean;
};

export const Tooltip: FC<TooltipProps> = ({ className, classes: extClasses, children, disabled, ...rest }) => {
  const classes = useStyles({ classes: extClasses });

  return !!disabled ? (
    <>{children}</>
  ) : (
    <MuiTooltip className={className} classes={classes} arrow {...rest}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
